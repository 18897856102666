
import {defineComponent, onMounted, reactive} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import NoteTemplates from "@/views/template/NoteTemplates.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TemplateForm from "@/views/template/TemplateForm.vue";
import Swal from "sweetalert2";
import TemplateService from "@/core/services/TemplateService";
import TaskTemplates from "@/views/template/TaskTemplates.vue";

export default defineComponent({
  name: "List",
  components: {TaskTemplates, TemplateForm, BaseModal, NoteTemplates, QuickAction},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Templates',
        [
          {link: false, router: '', text: 'Templates'}
        ]
      )
    })
    const state = reactive({
      templateId: '',
      template_: {type: 'NOTE', name: '', subject: '', body: '', companyId: '', shared: false}
    })
    return {
      state,
    }
  },
  methods: {
    onTemplateSaved(res) {
      switch (res.type.code) {
        case 'TASK':
          (this.$refs.taskTemplates as typeof TaskTemplates).paginationLoad();
          break
        case 'NOTE':
          (this.$refs.noteTemplates as typeof NoteTemplates).paginationLoad();
          break
      }
      const baseModal = this.$refs.refTemplateModal as typeof BaseModal
      baseModal.hideBaseModal()
    },
    onEdit(template) {
      this.state.templateId = template.id
      this.state.template_.type = template.type.code;
      this.state.template_.name = template.name;
      this.state.template_.subject = template.subject;
      this.state.template_.body = template.body;
      this.state.template_.companyId = template?.company?.id;
      this.state.template_.shared = template.shared;
      const baseModal = this.$refs.refTemplateModal as typeof BaseModal
      baseModal.showBaseModal()
    },

    onDelete(template: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TemplateService.delete(template.id).then(() => {
            switch (template.type.code) {
              case 'TASk':
                (this.$refs.taskTemplates as typeof TaskTemplates).paginationLoad();
                break
              case 'NOTE':
                (this.$refs.noteTemplates as typeof NoteTemplates).paginationLoad();
                break

            }
          })
        }
      })
    },
    onAddTemplate() {
      this.state.templateId = ''
      this.state.template_ = {type: 'NOTE', name: '', subject: '', body: '', companyId: '', shared: false}
    }
  }
})
