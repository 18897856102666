
import { defineComponent, ref, watch } from "vue";
import * as Yup from "yup";
import { Field } from "vee-validate";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import Froala from "@/components/base/editor/Froala.vue";
import TemplateService from "@/core/services/TemplateService";
import { TEMPLATE_TYPE } from "@/core/config/Constant";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import { CompanySelect } from "@/core/composite/composite";

export default defineComponent({
  name: "TemplateForm",
  components: {BaseSelect, Froala, FormErrorMessage, Field, BaseRadio, BaseForm},
  props: {
    templateId: {type: String},
    template: {type: Object}
  },
  emits: ['templateSaved'],
  setup(props, {emit}) {
    const model = ref(props.template)
    watch(()=>props.template, (cb)=>{
      model.value = cb;
    })
    const submitting = ref(false)
    const validate = Yup.object().shape({
      name: Yup.string().required().label("name"),
    });
    const onSubmit = () => {
      submitting.value = true;
      if (props.templateId) {
        TemplateService.update(props.templateId, model.value).then(res => {
          emit('templateSaved', res)
        }).finally(() => {
          submitting.value = false
        })
      } else {
        TemplateService.create(model.value).then(res => {
          emit('templateSaved', res)
        }).finally(() => {
          submitting.value = false
        })
      }
    }
    return {
      ...CompanySelect(),
      model,
      validate,
      submitting,
      onSubmit,
      TEMPLATE_TYPE
    }
  }
})
